.link {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
}

.messageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
}

.linkDisabled {
  pointer-events: none;
}
