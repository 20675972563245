.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extraData {
  padding: 0 0 0 1.5rem;
  color: rgba(0, 0, 0, 0.6);
}

.innerContainer {
  display: flex;
  min-height: 52px;
  align-items: center;
}

.inputContainer {
  display: flex;
  margin-right: 26px;
  align-items: center;
}

.input,
.input div {
  height: 32px;
  width: 50px;
  font-size: 12px;
}

.input input {
  padding: 0 5px;
  text-align: center;
}

.inputDescription {
  margin-right: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 12px;
}

.selectDescription {
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 2px;
}

.selectContainer {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.selectContainer div {
  height: 32px;
}

.selectContainer fieldset {
  border: none;
}

.selectContainer svg {
  top: calc(50% - 12px);
}

.select {
  height: 32px;
  font-size: 12px !important;
  padding-left: 0 !important;
}
