.charts {
  width: 100%;
  overflow: auto;
  position: relative;
  padding: 0 0 20px 0;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem 0.8rem;
  position: absolute;
  left: 0;
  top: 0;
  width: 95%;
}

.headerLeft,
.headerRight {
  font-size: 1.4rem;
  position: relative;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
}

.headerRight {
  flex-grow: 1;
}

.headerLeft {
  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.87);
    width: 3px;
    height: 1.6rem;
    position: absolute;
    left: 1rem;
    display: block;
  }
}

.switchButtons {
  border-radius: 3px;
  height: 46px;
  display: flex;
  align-items: center;
  align-content: center;
}

.switchButton {
  padding: 0 15px;
  cursor: pointer;
  height: 100%;
  align-items: center;
  display: flex;
  color: #757575;
  letter-spacing: 0.03em;
  font-weight: 400;
  font-size: 16px;
}

.switchButtonLeft {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid #d7dde2;
}

.switchButtonLeftActive,
.switchButtonRightActive {
  background-color: #078a51;
  color: #fff;
  border-radius: 3px 0 0 3px;
}

.switchButtonLeftActive {
  border-right: 1px solid #078a51;
}

.switchButtonRight {
  border-radius: 0 3px 3px 0;
  border-top: 1px solid #d7dde2;
  border-bottom: 1px solid #d7dde2;
  border-right: 1px solid #d7dde2;
}

.switchButtonRightActive {
  border-top: 1px solid #078a51;
  border-bottom: 1px solid #078a51;
  border-right: 1px solid #078a51;
}

.switchButtonCenter {
  border-radius: none;
  border-top: 1px solid #d7dde2;
  border-bottom: 1px solid #d7dde2;
  border-right: 1px solid #d7dde2;
}

.switchButtonCenterActive {
  background-color: #078a51;
  border-top: 1px solid #078a51;
  border-bottom: 1px solid #078a51;
  border-right: 1px solid #078a51;
  color: #fff;
}

.content {
  display: flex;
  margin: 80px 0 0 0;
  padding: 0 1rem;
  width: 100%;
}

.contentLeft {
  min-width: 300px;
  flex-shrink: 0;
  align-self: flex-start;
}

.contentRight {
  width: 100%;
  flex-grow: 1;
  padding: 0 1rem;
  display: flex;
}

.krdData {
  border: 1px solid #d7dde2;
  border-radius: 12px;
}

.krdDataTop {
  padding: 16px;
  border-bottom: 1px solid #d7dde2;
  display: flex;
  align-items: center;
}

.krdDataTopLeft {
  flex-grow: 1;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.krdDataSeeMore {
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
}

.krdDataBottom {
  padding: 16px;
  display: flex;
}

.data {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  flex-direction: column;

  &:first-child {
    margin: 0 2.4rem 0 0;
  }
}

.dataTop {
  width: 100%;
}

.dataBottom {
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #f91818;
  text-align: center;
  width: 100%;
}
