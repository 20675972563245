$maxVisibleElementsCount: 10;

.autoCompleteListbox {
  max-height: calc($maxVisibleElementsCount * 32px * 0.9 + 1rem) !important;
}

.taxIdentificationNumberHeader {
  color: grey;
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.taxIdentificationNumber {
  color: black;
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.companyName {
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
  font-size: 0.9rem;
}
