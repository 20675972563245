.salesChart {
  display: flex;
  background: #EFEFEF;
  border-radius: 3px;
  height: 18px;
  width: 100%; 
  max-width: 42.7rem;
}

.redSalesChart {
  display: flex;
  background: #EFEFEF;
  border-radius: 3px;
  height: 18px;
  width: 100%; 
  max-width: 42.7rem;
}

.salesChartLine {
  border-radius: 3px;
  height: 18px;
  background: #2360BC;
  display: flex;
}

.redSalesChartLine {
  border-radius: 3px;
  height: 18px;
  background: #E52713;
  display: flex;
}

.salesChartTopLine, .redSalesChartTopLine {
  background: #FFFFFF;
  opacity: 0.1;
  width: 100%;
  border-radius: 3px;
  height: 50%;
  align-self: flex-end;
}