.box {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 228px;
  height: 40px;
}

.textBox {
  font-weight: 300;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: #242424;
  padding: 5rem 0 4rem 0;
  display: flex;
  justify-content: center;
}

.text {
  width: 100%;
  max-width: 850px;
}

.separator {
  width: 100%;
}
