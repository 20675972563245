.kaczmarski {
  height: 24px;
  margin-top: 5px;
}

.link {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
