.generetingReportBox {
  width: calc(100% - 40px);
  max-width: 492px;
  height: auto;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 34px 24px -14px rgba(0, 0, 0, 0.08), 0px 19px 7px -17px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  margin: 0 auto;
  position: fixed;
  transform: translateY(-50%);
  top: 50%;
}

.generetingReportTop {
  height: 72px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 24px;
}

.generetingReportTopLeft {
  font-weight: 500;
  font-size: 18px;
}

.generetingReportLogo {
  width: 127px;
}

.generetingReportBottom {
  padding: 42px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generetingReportBottomLeft {
  display: flex;
  justify-content: center;
  width: 90px;
  flex-shrink: 0;
}

.generetingReportBottomRight {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.generetingReportBottomMessage2 {
  color: #078a51;
  font-weight: 500;
}

.generetingReportBottomMessageError {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #f51b42;
}

.generetingReportError {
  width: 45px;
  height: 45px;
  background: #f51b42;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.pdf {
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .generetingReportBox {
    width: 492px;
  }
}
