.box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  white-space: nowrap;
}

.inactiveText {
  color: #bdbdbd;
}

.boxCenter {
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.iconYellowColor {
  color: #ec9514;
}

.iconWarning {
  color: #ea9617;
  margin-left: '0.5rem';
}

.justifyRight {
  justify-content: right;
}

.iconTooltip {
  max-width: '8.5rem';
  width: 'fit-content';
  text-overflow: 'ellipsis';
  overflow: 'hidden';
}
