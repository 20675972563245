h2,
h3 {
  margin: 0;
  padding: 0;
  font-weight: unset;
}

.sectionBox {
  padding: 0 1.5rem;
}

.sectionHeader {
  font-size: 20px;
  margin-top: 20px;
}

.reportContentBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 2rem;
}

.reportContentError {
  color: #d32f2f;
  margin-top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 400;
}

.reportContentSectionHeader {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.reportContentSection {
  width: 30rem;
}

.radioButtonBox {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
}

.collapseContentBox {
  display: flex;
  flex-direction: column;
}

.comboBoxGroup {
  display: flex;
  justify-content: space-between;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.collapseContent {
  margin-left: 1.5rem;
}

.collapseContent.hidden {
  display: none;
}
