.wrapper {
  display: flex;
}

.innerWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.header {
  padding: 0 8px;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15rem 0;
}
