.container {
  display: flex;
  padding: 0 0 0 0.3rem;
}

.containerPart {
  display: flex;
  flex-direction: column;
}

.containerPartHalf {
  width: 40%;
}

.containerPartFull {
  width: 100%;
}

.checkboxContainer {
  width: 100%;
}
