.item {
  padding: 10px 12px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  font-weight: 400;
  font-size: 16px;
  color: #374151;
  cursor: pointer;
}

.itemFirst {
  border-top: unset;
}

.showMore {
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  text-decoration-line: underline;
  color: #078a51;
  padding: 20px 12px 8px 12px;
  cursor: pointer;
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.noItems {
  padding: 10px 12px;
}

.tickIcon {
  position: absolute;
  bottom: -4px;
  right: -6px;
}
