.wrapper {
  display: flex;
}

.innerWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.header {
  padding: 0 8px;
}

.trHead {
  background: #fafafa;
  box-shadow: inset 0px -1px 0px #e0e0e0;

  td > div > div {
    width: 100%;
    padding-right: 48px;
    background: #fafafa;
  }

  td > div > div > div {
    background: #fff;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15rem 0;
}
