.wrapper {
  display: flex;
}

.innerWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.header {
  padding: 0 8px;
}

.trHead {
  background: #fafafa;
  box-shadow: inset 0px -1px 0px #e0e0e0;

  input {
    width: 100px;
  }

  td > div > div > div {
    max-width: 160px;
  }
}

.newValue {
  div {
    margin: 0 auto 0 auto;
  }
}

.filterSection {
  padding: 16px 24px;

  &:last-of-type {
    border-top: 1px solid #e0e0e0;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15rem 0;
}
