* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

html {
  height: 100vh;
}

body {
  height: 100%;
  background: #e7ebf0;
  display: flex;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
}

img {
  height: 100%;
  width: 100%;
}

.MuiAccordion-root {
  box-shadow: none;
  border-top: 1px solid #e0e0e0;
}

.MuiAccordion-root:last-of-type {
  box-shadow: none;
}

#root {
  width: 100%;
  display: grid;
  align-content: baseline;
}
