.tutorialMenuSectionWrapper {
  border-top: 1px solid #e0e0e0;
}

.tutorialMenuSectionWrapper:first-child {
  border-top: none;
}

.menuLink {
  display: flex;
  padding: 0.25rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    background-color: rgba(7, 138, 81, 0.1);
    color: #078a51;
  }
}

.section {
  padding: 0.5rem 1.5rem;

  .sectionHeader {
    display: flex;
    font-size: 20px;
    color: rgba(7, 138, 81, 1);
    margin: 1rem 0;
    font-weight: 500;
  }

  .sectionsWrapper {
    margin: 1rem 0 2rem 0;
  }
}

.subsection {
  margin: 0 0 1.5rem 1.5rem;

  .subsectionHeader {
    display: flex;
    align-items: flex-start;
    font-size: 18px;
    padding: 1rem 0 0.5rem 0;
    font-weight: 500;

    a {
      opacity: 0;
      display: flex;
    }

    &:hover {
      a {
        opacity: 1;
      }
    }
  }
}

.tutorialSubSectionWrapper {
  p,
  ul,
  ol,
  li {
    margin: revert;
    list-style: revert;
    padding: revert;
  }

  a {
    text-decoration: revert;
  }

  .tutorialImageWrapper {
    display: flex;
    justify-content: flex-start;
  }

  .verticalImg,
  .horizontallImg {
    height: auto;
    width: auto;
  }

  .verticalImgFullHeight {
    height: 100%;
  }

  .verticalImg {
    max-width: 50%;
  }

  .horizontallImg {
    max-width: 100%;
  }

  .horizontallImgWithMarginTop {
    margin-top: 20px;
  }

  .horizontallImgWithMarginBottom {
    margin-bottom: 20px;
  }

  h4 {
    text-decoration: underline;
  }
}

.blueLink {
  color: #0663c1;
}

.table {
  border: 1px solid black;
  width: 100%;
  border-collapse: collapse;
}

.th {
  width: 33%;
  text-align: left;
  font-weight: 400;
  background-color: #e0e0e0;
  border: 1px solid black;
  padding-left: 5px;
  padding-bottom: 30px;
}

.scoringTh {
  width: 45%;
  text-align: left;
  font-weight: 400;
  background-color: #e0e0e0;
  border: 1px solid black;
  padding-left: 5px;
  padding-bottom: 30px;

  &:first-of-type {
    width: 10%;
  }
}

.td {
  border: 1px solid black;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: top;
}

.tableOrderedList {
  list-style: none !important;
}

.counter {
  margin-left: -20px;
}

.textUnderline {
  text-decoration: underline;
}

.separator {
  height: 15px;
}

.squareList {
  list-style-type: square;
}

.bold {
  font-weight: 600;
}
