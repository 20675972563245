.wrapper {
  background: #e7e6e6;
  padding: 1rem 1.5rem;
  margin: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.title {
  color: rgb(7, 138, 81);
  font-weight: 500;
  font-size: 20px;
}

.point {
  color: rgb(7, 138, 81);
  text-decoration: none;
  margin-right: 5px;
}

.row {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  margin-bottom: 5px;
}

.question {
  text-decoration: underline;
}

.list {
  margin-top: 25px;
}
