
.sidebar {
  width: 100%;
  max-width: 20rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  & div:last-child {
    flex-grow: 1;
  }
}