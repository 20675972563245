.container {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e0e0e0;
}

.containerLast {
  border-bottom: none;
}

.title {
  display: flex;
  padding: 0.4rem 0;
}
