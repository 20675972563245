.button {
  background: none;
  border: none;
  outline: none;
  margin-left: auto;
  display: block;
}

.hourglassIcon {
  margin-left: auto;
  display: block !important;
  color: #ec9514;
}

.row {
  cursor: pointer;
}

.rowNotClickable {
  cursor: auto;
}

.tableTd {
  padding-left: 30px;
}
