.borderedBoxForIcon {
  padding: 1rem 1.5rem;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #000;
  max-width: 42.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
}

.blackIcon {
  background: #000;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  color: #fff;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.containerBox {
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  width: 100%;
  max-width: 45.75rem;
  overflow: auto;
}

.tradeCreditLimitBox {
  display: grid;
  align-items: center;
  border: 1px solid #000;
  margin-top: 1rem;
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
  width: 100%;
  height: 4.1rem;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}

.boldText {
  font-weight: 600;
}
