.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-weight: bold;
  text-align: center;
  padding: 20px 0 0 0;
}
